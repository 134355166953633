<template>
  <v-container fluid class="px-lg-10">
    <SettingsSectionSearch
    @search="getSettings"
    @reset="getSettings"
    />

    <v-tabs
    class="mt-6"
    v-model="tab"
    v-if="sectionOptions.length"
    >
      <v-tab
        :href="`#${section}`"
        :key="section"
        v-for="section in sectionOptions"
      >
        {{ section }}
      </v-tab>
      <v-tab-item
        :class="`pa-1`"
        :key="section"
        :value="`${section}`"
        v-for="section in sectionOptions"
      >
        <div
          :class="`px-3 rounded-sm`"
          :key="setting.key"
          v-for="setting in filteredBySection(section)"
        >
          <SettingRow
            v-bind="setting"
            :title="setting.key"
            :actions="actions"
          />
        </div>
      </v-tab-item>
    </v-tabs>
    <v-row v-else class="mt-6">
      <v-col>
      <v-card>
        <v-alert>Nessuna impostazione trovata</v-alert>
      </v-card>
      </v-col>
    </v-row>

    <v-dialog
      :fullscreen="$vuetify.breakpoint.mobile"
      :max-width="400"
      :retain-focus="false"
      v-model="dialog"
    >
      <v-card>
        <v-card-text>
          <SettingsForm
            :edit="editMode"
            :title="`Modifica configurazione`"
            @submitted="onSubmitted"
            v-if="dialog"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import SettingRow from '@/src/components/settings/SettingRow.vue'
import SettingsForm from '@components/settings/SettingsForm.vue'
import SettingsSectionSearch from '@components/settings/SettingsSectionSearch.vue'

export default {
  name: 'Settings',
  components: {
    SettingRow,
    SettingsForm,
    SettingsSectionSearch
  },
  mounted() {
    this.getSettings()
  },
  data() {
    return {
      dialog: false,
      editMode: false,
      tab: null,
    }
  },
  computed: {
    ...mapState('settings', {
      filteredBySection: (state) => (section) =>
        state.list.filter((setting) => setting.section === section),
      sectionOptions: (state) => [
        ...new Set(state.list.map((setting) => setting.section)),
      ],
    }),
    actions() {
      return [
        {
          text: 'Modifica',
          icon: 'mdi-pencil',
          color: 'primary',
          handler: this.openModal,
        },
      ]
    },
  },
  methods: {
    ...mapActions('settings', {
      getSettings: 'getDropdownList',
    }),
    ...mapMutations('settings', {
      setCurrent: 'SET_CURRENT',
      resetCurrent: 'RESET_CURRENT',
    }),

    openModal(item) {
      const startingPoint = item
      // Understand if the modal was opened as editing or for a new sub
      const isEditing = !!startingPoint.key
      this.editMode = isEditing
      this.setCurrent(startingPoint)
      this.dialog = true
    },
    onSubmitted() {
      this.modalClose()
      this.getSettings()
    },
    modalClose() {
      this.dialog = false
      this.editMode = false
      this.resetCurrent()
    },
  },
}
</script>
