<template>
  <v-card elevation="0" class="setting-row">
    <v-row class="d-flex justify-space-between">
      <v-col class="title-container d-flex align-end overline black--text">
        {{ title }}
      </v-col>
      <v-col class="d-flex justify-space-between" cols="12" md="6">
        <div
          v-if="type === 'boolean'"
          class="boolean-container readonly-check"
          style=""
        >
          <v-checkbox
            :input-value="JSON.parse(referenceValue)"
            readonly
          ></v-checkbox>
        </div>
        <div v-else class="string-container d-flex align-end pb-1">
          <code>{{ referenceValue }} </code>
        </div>

        <span v-if="canUser('admin.settings', 'update')">
          <Action
            :key="JSON.stringify(action)"
            :target="{ ...$props, key: title }"
            v-bind="action"
            v-for="action in actions"
          />
        </span>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col>
        <small>{{ description }}</small>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { authComputed } from '@/src/state/helpers'
import { isNil } from 'lodash'
import Action from '@components/structure/action.vue'

export default {
  name: 'SettingRow',
  components: {
    Action,
  },
  props: {
    actions: {
      default: () => [],
      type: Array,
    },
    default_value: {
      type: String,
    },
    description: {
      required: true,
      type: String,
    },
    order: {
      type: Number,
    },
    section: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    ttl: {
      type: Number,
    },
    type: {
      required: true,
      type: String,
    },
    updated_at: {
      required: true,
      type: String,
    },
    value: {
      required: true,
    },
  },
  computed: {
    ...authComputed,
    referenceValue() {
      if (isNil(this.value)) return this.default_value
      return this.value
    },
  },
}
</script>

<style lang="scss">
.setting-row {
  .title-container {
    line-height: 20px;
  }
  .boolean-container {
    margin-top: 5px;
    .readonly-check {
      .v-input--selection-controls {
        margin-top: 0;
      }
      .v-input__slot {
        margin-bottom: 0;
      }
      .v-messages {
        display: none;
      }
    }
  }
  .string-container {
    overflow: hidden;
    code {
      white-space: pre;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
