<template>
  <Form :hide-reset="edit" :title="title" @submit="onSubmit" @reset="onReset">
    <v-row v-if="type === 'string' || type === 'raw'" class="pb-3">
      <FormItem
        :cols="12"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
        :name="`Valore`"
        :rules="rules"
        :type="`text`"
        v-model="value"
      />
      <v-col cols="12" v-if="hasDefault">
        <span class="overline">Valore di default</span>
        <v-divider></v-divider>
        <code>{{ default_value }}</code>
      </v-col>
    </v-row>
    <v-row v-if="type === 'integer' || type === 'float'" class="pb-3">
      <FormItem
        :cols="12"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
        :name="`Valore`"
        :rules="rules"
        :step="type === 'integer' ? 1 : 0.00001"
        :type="`number`"
        :value="value"
        @input="(val) => (value = +val)"
      />
      <v-col cols="12" v-if="hasDefault">
        <span class="overline">Valore di default</span>
        <v-divider></v-divider>
        <code>{{ default_value }}</code>
      </v-col>
    </v-row>
    <v-row v-if="type === 'json'" class="pb-3">
      <FormItem
        :cols="12"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
        :name="`Valore`"
        :rules="rules"
        :type="`textarea`"
        v-model="value"
      />

      <v-col cols="12">
        <span class="overline">Anteprima</span>
        <v-divider></v-divider>
        <code
          style="
            display: block;
            max-height: 150px;
            max-width: 100%;
            overflow: auto;
            text-overflow: ellipsis;
            white-space: pre-wrap;
          "
          >{{ value }}</code
        >
      </v-col>

      <v-col cols="12" v-if="hasDefault">
        <span class="overline">Valore di default</span>
        <v-divider></v-divider>
        <code>{{ default_value }}</code>
      </v-col>
    </v-row>
    <v-row v-if="type === 'boolean'" class="pb-3">
      <FormItem
        :cols="12"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
        :name="`Valore`"
        :rules="{ required: true }"
        :type="`switch`"
        :value="JSON.parse(value)"
        @input="(val) => (value = !!val)"
      />
      <v-col cols="12" v-if="hasDefault">
        <span class="overline">Valore di default</span>
        <v-divider></v-divider>
        <FormItem
          :cols="12"
          :sm="12"
          :md="12"
          :lg="12"
          :xl="12"
          :disabled="true"
          :name="`Default`"
          :rules="{ required: true }"
          :type="`switch`"
          :value="JSON.parse(default_value)"
        />
      </v-col>
    </v-row>
  </Form>
</template>

<script>
import { authComputed } from '@state/helpers.js'
import { createHelpers } from 'vuex-map-fields'
import { mapActions, mapGetters } from 'vuex'
import { set, isNil } from 'lodash'
import Form from '@components/forms/Form.vue'
import FormItem from '@components/forms/FormItem.vue'

const { mapFields: mapSetting } = createHelpers({
  getterType: 'settings/getCurrent',
  mutationType: 'settings/SET_CURRENT_FIELDS',
})

export default {
  name: 'SettingsForm',
  components: {
    Form,
    FormItem,
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Crea Nuovo',
    },
  },
  computed: {
    ...authComputed,
    ...mapGetters('types', ['typesOptions']),
    ...mapSetting([
      'default_value',
      'description',
      'key',
      'order',
      'section',
      'ttl',
      'type',
      'updated_at',
      'value',
    ]),
    rules() {
      const rules = {
        required: true,
      }
      switch (this.type) {
        case 'string':
          break
        case 'integer':
          set(rules, ['integer'], true)
          break
        case 'float':
          set(rules, ['number'], true)
          break
        case 'json':
          set(rules, ['valid_json'], true)
          break
        case 'raw':
          break
        case 'boolean':
          set(rules, ['boolean'], true)
          break
      }
      return rules
    },
  },
  methods: {
    ...mapActions('settings', ['create', 'update']),
    async onSubmit() {
      this.$emit('submit')

      if (!this.edit) {
        await this.create()
      } else {
        await this.update()
      }

      this.$emit('submitted', this.id)
    },
    onReset() {
      this.reset()
      this.$emit('resetted')
    },
    hasDefault() {
      return !isNil(this.default_value)
    },
  },
}
</script>

<style scoped></style>
